<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Producto - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
          
               <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input
                      type="text"
                      ref="code"
                      v-model="product.code"
                    ></b-form-input>
                    <small v-if="errors.code" class="form-text text-danger"
                      >Ingrese un código</small
                    >
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Categoria :" label-for="input-1">
                    <select
                      ref="id_category"
                      v-model="product.id_category"
                      class="form-control"
                    >
                      <option value="">-- Seleccione --</option>
                      <option
                        v-for="item of categories"
                        :key="item.id_category"
                        :value="item.id_category"
                      >
                        {{ item.name }}
                      </option>
                      
                    </select>
                    <small v-if="errors.id_category" class="form-text text-danger"
                      >Seleccione una categoria</small
                    >
                  </b-form-group>
                </b-col>
                 
    
                <b-col md="6">
                  <b-form-group label="Nombre:">
                    <b-form-input
                      type="text"
                      ref="name"
                      v-model="product.name"
                    ></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger"
                      >Ingrese un nombre</small
                    >
                  </b-form-group>
                </b-col>
     
                <b-col md="6">
                  <b-form-group label="Descripción:">
                    <b-form-input
                      type="text"
                      ref="description"
                      v-model="product.description"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
         
                <b-col md="3">
                  <b-form-group label="Tipo de existencia :" label-for="input-1">
                    <select
                      ref="existence_type"
                      v-model="product.existence_type"
                      class="form-control"
                    >
                      <option value="01">MERCADERÍAS</option>
                      <option value="99">OTROS</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Unidad de Medida :" label-for="input-1">
                    <select
                      ref="unit_measure"
                      v-model="product.unit_measure"
                      class="form-control"
                    >              
                     
                      <option value="NIU">UNIDAD (BIENES)</option>
                      <option value="ZZ">UNIDAD (SERVICIOS) </option>
                    </select>
                  </b-form-group>
                </b-col>
          
                <b-col md="3">
                  <b-form-group label="IGV :" label-for="input-1">
                    <select
                      ref="igv"
                      v-model="product.igv"
                      class="form-control"
                    >
                      <option value="10">Gravado</option>   
                      <option value="20">Exonerado</option>
                      <option value="30">Inafecto</option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Stock :">
                    <b-form-input
                      readonly
                      type="number"
                      class="text-right"
                      step="any"
                      ref="stock"
                      v-model="product.stock"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Precio Real :">
                    <b-form-input
                      type="number"
                      class="text-right"
                      step="any"
                      ref="real_price"
                      v-model="product.real_price"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

        
                
                <b-col md="2">
                  <b-form-group label="Precio Venta :">
                    <b-form-input
                      type="number"
                      class="text-right"
                      step="any"
                      ref="sale_price"
                      v-model="product.sale_price"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
           
                <b-col md="3">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="product.state"
                      class="form-control"
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
         


                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-button
                    type="submit"
                    class="form-control bg-primary text-white"
                    variant="primary"
                    >GUARDAR</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "ProductEdit",
  props: ["id_product"],
  data() {
    return {
      module:'Product',
      product: {
        id_product: "",
        id_category: "",
        code: "",
        name: "",
        short_name: "",
        description: "",
        existence_type: "",
        unit_measure: "",
        igv: "",
        stock: 0,
        photo: "",
        minimal_price: 0.00,
        real_price: 0.00,
        sale_price: 0.00,
        state: 1,
      },

      categories: [],
      //errors
      errors: {
        id_category: false,
        code: false,
        name: false,
        state: false,

      },
      validate: false,
    };
  },
  mounted() {
    this.ListCategories();
    this.ViewProduct();
  },
  methods: {
    ListCategories,
    ViewProduct,
    EditProduct,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function ListCategories() {
  let me = this;
  let url = this.url_base + "active-categories";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.categories = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//ver usuario
function ViewProduct() {
  let id_product = je.decrypt(this.id_product);
  let me = this;
  let url = this.url_base + "product/view/" + id_product;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.product.id_product = response.data.result.id_product;
        me.product.id_category = response.data.result.id_category;
        me.product.code = response.data.result.code;
        me.product.name = response.data.result.name;
        me.product.short_name = response.data.result.short_name;
        me.product.description = response.data.result.description;
        me.product.existence_type = response.data.result.existence_type;
        me.product.unit_measure = response.data.result.unit_measure;
        me.product.igv = response.data.result.igv;
        me.product.stock = response.data.result.stock;
        me.product.photo = response.data.result.photo;
        me.product.minimal_price = response.data.result.minimal_price;
        me.product.real_price = response.data.result.real_price;
        me.product.sale_price = response.data.result.sale_price;
        me.product.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//editar usuario
function EditProduct(_this) {


  let me = _this;
  let url = me.url_base + "product/edit";
  let data = me.product;

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: 'Se ha modificado el producto', timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//validacion de formulario
function Validate() {
  this.errors.id_category = this.product.id_category.length == 0 ? true : false;
  this.errors.name = this.product.name.length == 0 ? true : false;
  this.errors.code = this.product.code.length == 0 ? true : false;
  this.errors.state = this.product.state.length == 0 ? true : false;
 

 if (this.errors.id_category) { this.validate = true; return false;}else{ this.validate = false; }
if (this.errors.name) { this.validate = true; return false;}else{ this.validate = false; }
if (this.errors.code) { this.validate = true; return false;}else{ this.validate = false; }
if (this.errors.state) { this.validate = true; return false;}else{ this.validate = false; }

    let me = this;

    Swal.fire({
      title: 'Esta seguro de modificar los datos del producto ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Estoy de Acuerdo!'
    }).then((result) => {
      if (result.isConfirmed) {
        EditProduct(me);
      }
    })

}
</script>
